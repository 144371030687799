/**
 * Direttiva che costruisce la barra di ricerca con i filtri lato amministratore
 */
import { Item, User, SenecaResponse, UserGroup } from "atfcore-commonclasses";

export interface ISearchBarAdminDirectiveScope extends ng.IScope {
	libraryApplicationData: any;
	isBackBtnDisabled: boolean;
	filters: any;
	searchId: number;
	isSearchBtnDisabled: boolean;
	isDoingSomething: boolean;
	hasTabsAtLeastOneFilter: Function;
	isRemoveAllFiltersBtnDisabled: boolean;
	countTabFilters: Function;
	selectedTopicClass: string;
	filtersOpened: any;
	subTitleCategory: string;
	subTitleCluster: string;
	clustersSelected: boolean;
	clusterListSubTitle: string;
	clusterList: any;
	clusterElementCSS: string;
	subTitleFunctionalArea: string;
	funcionalAreaSelected: boolean;
	subTitleLevel: string;
	subTitleLang: string;
	levelListSubTitle: string;
	langListSubTitle: string;
	levelList: any;
	langList: any;
	atLeastOneCategorySelected: boolean;
}
angular.module('app').directive("searchBarAdmin", ($window, $state, LibraryApplicationData, GlobalApplicationData, $document, moment, $stateParams, $sessionStorage, $mdDialog, blockUI, LearningPlanService, toaster, $translate, $rootScope, ProjectService, VisibilityService, $timeout, ItemDetailService, GroupService) => {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			filters: '=',
			saveNewSearch: '=',
			historyBack: '=',
			searchId: '=',
			isDoingSomething: '=',
			hasTabsAtLeastOneFilter: '=',
			removeFilters: '=',
			countTabFilters: '=',
			subTabLibraryFiltersChanged: '=',
			isThirdTabSelected: '=',
			isFifthTabSelected: '=',
			inputPlaceholder: '=',
			inputPlaceholderGroup: '=',
			filtersOpened: '=',
			expandFilters: '=',
			categoryList: '=',
			clusterList: '=',
			getClusterClass: '=',
			unsetLibraryFilter: '=',
			setLibraryFilter: '=',
			functionalAreaList: '=',
			levelList: '=',
			langList: '=',
			typeList: '=',
			filtersContainerClass: '=',
			competenceList: '=',
			statusList: '=',
			mergedCompetenceList: '=',
			streamTagList: '=',
			categoryIndex: '=',
			functionalAreaIndex: '=',
			competenceIndex: '=',
			changeLetters: '=',
			isFirstTabSelected: '=',
			isSecondTabSelected: '=',
			atLeastOneCategorySelected: '=',
			atLeastOneTypeSelected: '=',
			noApproachSelected: '=',
			techApproachSelected: '=',
			softApproachSelected: '=',
			viewedStatusClass: '=',
			notViewedStatusClass: '=',
			softApproachClass: '=',
			techApproachClass: '=',
			atLeastOneCompetenceSelected: '=',
			selectedCompetencesLength: '=',
			adminTagList: '=',
			softSkillsTags: '='
		},
		link: link,
		templateUrl: 'app/shared/searchBarAdmin/searchBarAdmin.html'
	};
	function link($scope: ISearchBarAdminDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Collegamento all'oggetto con le costanti
		$scope.libraryApplicationData = LibraryApplicationData;

		// Se non ho ancora effettuato una ricerca, disabilito il pulsante per tornare indietro a fianco l'input di ricerca
		if ($scope.searchId <= 1) {
			$scope.isBackBtnDisabled = true;
		}

		// Se non ho nessun filtro, disabilito il pulsante per rimuovere tutti i filtri (tranne le categorie)
		if (!$scope.countTabFilters(true)) {
			$scope.isRemoveAllFiltersBtnDisabled = true;
		}

		// Calcolo la class per l'argomento selezionato
		if ($scope.atLeastOneCategorySelected) {
			$scope.selectedTopicClass = 'icon-with-category-selected';
			$scope.subTitleCategory = "1 " + $translate.instant('librarySubFilters.CATEGORY_SELECTED');
		} else {
			$scope.selectedTopicClass = 'icon-without-category-selected';
			$scope.subTitleCategory = "0 " + $translate.instant('librarySubFilters.CATEGORIES_SELECTED');
		}

		// Testo in traduzione nei cluster
		if ($scope.filters.selectedClusterTags && $scope.filters.selectedClusterTags.length) {
			// Segnalo che è stato selezionato almeno un cluster
			$scope.clustersSelected = true;
			if ($scope.filters.selectedClusterTags.length == 1) {
				$scope.subTitleCluster = $translate.instant('librarySubFilters.CLUSTER_SELECTED');
			} else {
				$scope.subTitleCluster = $translate.instant('librarySubFilters.CLUSTERS_SELECTED');
			}
		} else {
			$scope.subTitleCluster = $translate.instant('librarySubFilters.CLUSTERS_SELECTED');
		}

		// Aggiungo l'etichetta appropriata per la lista dei cluster da selezionare
		if ($scope.clusterList && $scope.clusterList.length) {
			if ($scope.clusterList.length == 1) {
				$scope.clusterListSubTitle = $translate.instant('librarySubFilters.CLUSTERS_TOTAL');
			} else {
				$scope.clusterListSubTitle = $translate.instant('librarySubFilters.CLUSTERS_TOTAL');
			}
		} else {
			$scope.clusterListSubTitle = $translate.instant('librarySubFilters.CLUSTERS_TOTAL');
		}

		// Calcolo la classe CSS che deve avere il cluster da selezionare
		if ($scope.filters.selectedClusterTags && $scope.filters.selectedClusterTags.length) {
			$scope.clusterElementCSS = "element-disabled opacity-layer";
		}

		// Aggiungo l'etichetta per il filtro delle aree funzionali
		if ($scope.filters.selectedFunctionalAreaTags && $scope.filters.selectedFunctionalAreaTags.length) {
			// Segnalo che c'è almeno un'area funzionale selezionata
			$scope.funcionalAreaSelected = true;
			if ($scope.filters.selectedFunctionalAreaTags.length == 1) {
				$scope.subTitleFunctionalArea = $translate.instant('librarySubFilters.FUNCTIONAL_AREA_SELECTED');
			} else {
				$scope.subTitleFunctionalArea = $translate.instant('librarySubFilters.FUNCTIONAL_AREAS_SELECTED');
			}
		} else {
			$scope.subTitleFunctionalArea = $translate.instant('librarySubFilters.FUNCTIONAL_AREAS_SELECTED');
		}

		// Aggiungo l'etichetta per il filtro dei livelli
		if ($scope.filters.selectedLevelTags && $scope.filters.selectedLevelTags.length) {
			if ($scope.filters.selectedLevelTags.length == 1) {
				$scope.subTitleLevel = $translate.instant('librarySubFilters.LEVEL_SELECTED');
			} else {
				$scope.subTitleLevel = $translate.instant('librarySubFilters.LEVELS_SELECTED');
			}
		} else {
			$scope.subTitleLevel = $translate.instant('librarySubFilters.LEVELS_SELECTED');
		}

		// Aggiungo l'etichetta per il filtro delke lingue
		if ($scope.filters.selectedLangTags && $scope.filters.selectedLangTags.length) {
			if ($scope.filters.selectedLangTags.length == 1) {
				$scope.subTitleLang = $translate.instant('librarySubFilters.LANG_SELECTED');
			} else {
				$scope.subTitleLang = $translate.instant('librarySubFilters.LANGS_SELECTED');
			}
		} else {
			$scope.subTitleLang = $translate.instant('librarySubFilters.LANGS_SELECTED');
		}

		// Aggiungo l'etichetta appropriata per la lista dei livelli da selezionare
		if ($scope.levelList && $scope.levelList.length) {
			if ($scope.levelList.length == 1) {
				$scope.levelListSubTitle = $translate.instant('librarySubFilters.LEVEL_TOTAL');
			} else {
				$scope.levelListSubTitle = $translate.instant('librarySubFilters.LEVELS_TOTAL');
			}
		} else {
			$scope.levelListSubTitle = $translate.instant('librarySubFilters.LEVELS_TOTAL');
		}
		
		// Aggiungo l'etichetta appropriata per la lista dei livelli da selezionare
		if ($scope.filters.selectedLangTags && $scope.filters.selectedLangTags.length) {
			if ($scope.filters.selectedLangTags.length == 1) {
				$scope.langListSubTitle = $translate.instant('librarySubFilters.LANG_TOTAL');
			} else {
				$scope.langListSubTitle = $translate.instant('librarySubFilters.LANGS_TOTAL');
			}
		} else {
			$scope.langListSubTitle = $translate.instant('librarySubFilters.LANGS_TOTAL');
		}
	}
});